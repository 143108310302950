export class RoutesService {
  public static readonly token = 'RoutesService';
  // Only used for sharing on the frontend, must not end with slash
  private origin;
  private root = '/';
  constructor(root = '/', origin = '/') {
    this.origin = origin;
    this.root = root;
  }
  public forSharing(parts: (string | number)[]) {
    const withoutOrigin = this.absolute(parts);
    return this.origin + withoutOrigin;
  }
  /**
   *
   * @param parts
   * @returns string of the link always starting with / for relative frontend links or the full link for absolute links on the backend
   */
  public absolute(
    parts: (string | number)[],
    params?: Record<string, string | number>
  ): string {
    // Should add a / to the root??
    const slash =
      this.root.endsWith('/') || (parts[0] + '').startsWith('/') ? '' : '/';

    let url = this.root + slash + parts.join('/');

    if (params) {
      const paramsString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
      url = `${url}?${paramsString}`;
    }

    // Handle if starts with double slash
    if (url.startsWith('//')) {
      return url.slice(1);
    }
    return url;
  }
  /**
   * Home
   */
  public home() {
    return ['/home'];
  }
  /**
   * Auth
   */
  public login() {
    return ['/auth/login'];
  }
  public resetPassword() {
    return ['/auth/reset-password'];
  }
  public verifyLoginEmail() {
    return ['/auth/verify-email'];
  }

  public githubLoginCallbackURL() {
    return ['/api/auth/github/callback'];
  }

  public accountExpiration() {
    return ['/auth/account-expiration'];
  }
  public slackConnection() {
    return ['/auth/slack-connection'];
  }

  /**
   * Investor Hub
   */
  public investorHub() {
    return ['/investor-hub/home'];
  }
  public singleStartup(startupId: string) {
    return ['/investor-hub/startup', startupId];
  }
  public singleStartupRisks(startupId: string) {
    return ['/investor-hub/startup', startupId, 'risks'];
  }
  public startupApply() {
    return ['/startup-apply'];
  }

  /**
   * Projects
   */
  public projectHub() {
    return ['/projects/projects-home'];
  }
  public singleProject(projectId: string) {
    return ['/projects/project', projectId];
  }
  public singleProjectRisks(projectId: string) {
    return ['/projects/project', projectId, 'risks'];
  }

  /**
   * Runs list
   */
  public runsList() {
    return ['/wizard/runs'];
  }
  public report2() {
    return ['report'];
  }
  /**
   * Creating new run
   */
  public importingHome() {
    return ['/wizard/importing'];
  }
  public importingFromGithub() {
    return [`/wizard/importing/github`];
  }
  public importingJira(runId: string) {
    return ['/wizard/runs', runId, 'jira-import'];
  }
  public jiraInstructions(runId: string) {
    return ['/wizard/runs', runId, 'jira-instructions'];
  }
  public createDevelopers(runId: string) {
    return ['/wizard/runs', runId, 'developers'];
  }
  public createTeams(runId: string) {
    return ['/wizard/runs', runId, 'teams'];
  }
  public excludeFiles(runId: string) {
    return ['/wizard/runs', runId, 'files-exclusion'];
  }

  /**
   * Shallow Run Links
   */
  public shallowHome(runId: string) {
    return ['/wizard/shallow', runId, 'home'];
  }
  public importingShallowHome(runId: string) {
    return ['/wizard/shallow', runId, 'importing'];
  }
  public importingShallowJira(runId: string) {
    return ['/wizard/shallow', runId, 'jira-import'];
  }
  public createShallowDevelopers(runId: string) {
    return ['/wizard/shallow', runId, 'developers'];
  }
  public createShallowTeams(runId: string) {
    return ['/wizard/shallow', runId, 'teams'];
  }
  public landingPageForGithub() {
    return ['/wizard/shallow/github'];
  }
  public shallowRunStatus() {
    return ['/wizard/shallow/status'];
  }
  public shallowRunComplete() {
    return ['/wizard/shallow/complete'];
  }
  public selectRepoForGithub(runId: string) {
    return ['/wizard/shallow/', runId, 'github'];
  }
  /**
   * Inside a run
   */
  public runHome(runId: string) {
    return ['/runs', runId, 'analysis'];
  }

  public report(runId: string) {
    return ['/runs', runId, 'analysis', 'report'];
  }
  public analysisRisks(runId: string) {
    return ['/runs', runId, 'analysis', 'risks'];
  }

  public dependencies(runId: string) {
    return ['/runs', runId, 'analysis', 'dependencies'];
  }

  public codeAnalysis(runId: string, directoryId?: number) {
    const directory = directoryId ? '' + directoryId : '';
    return ['/runs', runId, 'analysis', 'code', directory];
  }
  public teamAnalysis(runId: string, teamId: number) {
    return ['/runs', runId, 'analysis', 'team', teamId];
  }

  public settings() {
    return ['/settings'];
  }

  /**
   * Profile
   */
  public profileAnalysis(runId: string, teamId: number, profileId: number) {
    return ['/runs', runId, 'analysis', 'team', teamId, 'profile', profileId];
  }
  public profilesCompare(
    runId: string,
    teamId: number,
    profileId: number,
    runId2: string,
    teamId2: number,
    profileId2: number
  ) {
    return [
      '/runs',
      runId,
      'analysis',
      'team',
      teamId,
      'profile',
      profileId,
      'vs',
      runId2,
      'team',
      teamId2,
      'profile',
      profileId2,
    ];
  }

  /**
   * Team
   */
  public teamsCompare(
    runId: string,
    teamId: number,
    runId2: string,
    teamId2: number
  ) {
    return [
      '/runs',
      runId,
      'analysis',
      'team',
      teamId,
      'vs',
      runId2,
      'team',
      teamId2,
    ];
  }

  /**
   * Organization
   *
   */

  public organizationAnalysis(runId: string, organizationId: number) {
    return ['/runs', runId, 'analysis', 'organization', organizationId];
  }
  public organizationsCompare(
    runId: string,
    organizationId: number,
    runId2: string,
    organizationId2: number
  ) {
    return [
      '/runs',
      runId,
      'analysis',
      'organization',
      organizationId,
      'vs',
      runId2,
      'organization',
      organizationId2,
    ];
  }

  /**
   * Hiring
   */
  public jobListing() {
    return ['/hiring/job-listing'];
  }
  public jobCreating() {
    return ['/hiring/job-creating'];
  }
  public jobView(jobId: string) {
    return ['/hiring/job', jobId, 'view'];
  }
  public jobEdit(jobId: string) {
    return ['/hiring/job', jobId, 'edit'];
  }
  public jobSubmissions(jobId: string) {
    return ['/hiring', 'job', jobId, 'submissions'];
  }

  public jobSingleSubmission(jobId: string, candidateId: string) {
    return ['/hiring', 'job', jobId, 'submissions', 'candidate', candidateId];
  }

  /**
   * Scans
   */

  scansList(): string[] {
    return ['/scans'];
  }
  scanItem(uuid: string): string[] {
    return ['/scans', uuid];
  }
  // Public pages
  public publicJobView(jobId: string) {
    return ['/job-apply', 'job', jobId, 'job-listing'];
  }
  public publicJobApply(jobId: string, candidateId: string) {
    return [
      '/job-apply',
      'job',
      jobId,
      'candidate',
      candidateId,
      'apply-process',
      'apply-steps',
    ];
  }
  public publicJobVerification(jobId: string, candidateId: string) {
    return [
      '/job-apply',
      'job',
      jobId,
      'candidate',
      candidateId,
      'verification',
    ];
  }
  public publicCVUpload(jobId: string) {
    return ['/job-apply/job', jobId, 'apply-process', 'cv-upload'];
  }
}
