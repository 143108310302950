import { Injectable, PLATFORM_ID, Inject, LOCALE_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location, isPlatformBrowser } from '@angular/common';
import { AnalyticsService } from './analytics.service.abstract';
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService extends AnalyticsService {
  private readonly mixpanel = mixpanel;
  override trackPageViews(): void {
    //
  }
  override identify(userId: string, properties: { [key: string]: any }): void {
    this.mixpanel.identify(userId);
    this.mixpanel.people.set(properties);
  }
  override trackEvent(eventName: string, eventProperties?: any): void {
    //
    this.mixpanel.track(eventName, eventProperties);
  }
  public init(token: string, extra: Record<string, any>): void {
    this.mixpanel.init(token, { ...extra, track_pageview: 'full-url' });
  }

  constructor() {
    super();
  }
}
